import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";

const ExecutiveCoachingSussexPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				mike: file(relativePath: { eq: "testimonials/pier-2.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100)
					}
				}
				heroImg: file(relativePath: { eq: "jason-client-meeting-2.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Executive Coach Sussex";
	const description = "";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const mike = data.mike.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Executive Coaching",
				item: {
					url: `${siteUrl}/executive-coaching`,
					id: `${siteUrl}/executive-coaching`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Sussex",
				item: {
					url: `${siteUrl}/executive-coaching/sussex`,
					id: `${siteUrl}/executive-coaching/sussex`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/executive-coaching/sussex`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Executive Coaching in Sussex",
						},
					],
				}}
			/>

			<Hero
				isDarken={true}
				backgroundImage={heroImage}
				title="Executive Coach Sussex"
				description1="Personal & Professional Executive Coaching for Leaders and Managers Across Sussex"
				description2=""
			/>

			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Personal Professional Executive Coaching for Leaders and
								Managers Across Sussex
							</h2>
							<p>
								Whether you’ve been feeling less productive or happy in your
								career lately or you feel like you're not living the life you
								want and you don't know what to do next, executive coaching
								might be the solution for you.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Speak to an executive coach in Sussex
							</h3>
							<p>
								Since becoming a business and executive coach in 2016, I’ve
								helped leaders, business owners and managers across the region
								become more confident, resilient and happy executives.
							</p>
							<p>
								From my base here in Eastbourne, I’ve helped clients from
								Hastings in East Sussex to Chichester in West Sussex with
								executive coaching to:
							</p>
							<ul className="pl-4">
								<li>Become more decisive</li>
								<li>Boost their self confidence</li>
								<li>Improve the way they communicate with staff</li>
								<li>Create a vision, make a plan and stay focused</li>
								<li>Boost their motivation and take decisive action</li>
								<li>
									Make tough and exciting decisions on new directions or
									business opportunities.
								</li>
								<li>Find out what they REALLY want to do in life.</li>
							</ul>
							<p>
								If you’re looking for something similar, you could benefit from
								some local executive coaching.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 mb-xl-1 service-page-body-image"
								src="../../images/jason-flip-chart.jpg"
							/>
							<h3 className="text-primary pb-3">
								Executive coaching in Sussex
							</h3>
							<p>
								As an executive coach, my job is to help, guide and support
								leaders and managers across Sussex find their way forward, set
								goals and hold them accountable. In your corner, listening,
								observing and offering unbiased comments, feedback, advice and
								suggestions to get you where you want to go and reach your
								potential.
							</p>
							<p>
								Whether it’s the rolling hills and white cliffs of East Sussex,
								picture-postcard villages like Boxgrove in West Sussex, sunshine
								and tourists by the coast in St Leonards, Eastbourne or
								Brighton, or even captivating castles in Bodiam or Arundel - our
								region has so much to celebrate and be glad about.
							</p>
							<p>
								And, just like the 70,000+ businesses across the hospitality,
								tourism, financial, manufacturing and creative sector in our
								region - there’s a diverse range of businesses to be proud of.
								Thousands of companies, managed by executives and leaders with a
								broad range of personalities and strengths. All with their own
								individual circumstances, challenges and opportunities for
								personal and professional development.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Work with an executive coach
							</h3>
							<p>
								Which is where I come in. As an executive coach, it’s my job to
								help leaders and managers like you, find their way forward.
								Overcoming the obstacles and challenges in their path, whether
								real or perceived. To date, I’ve helped many clients from all
								sorts of different businesses and organisations in our region do
								just this, with executive coaching that covers:
							</p>
							<ul className="pl-4">
								<li>Professional and personal development</li>
								<li>Leadership coaching</li>
								<li>Business coaching</li>
								<li>And career coaching</li>
							</ul>
							<p>
								Helping you to become stronger, more resilient and happier,
								wealthier, and confident in your personal and professional life.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">A local case study from Sussex</h3>
							<p>
								Take Mike who is a manager in an accountancy practise in West
								Sussex. Due to expansion and a merger, Mike found himself
								‘catapulted’ from working in a small family run business to
								managing a team of ten and had accepted a promotion to the
								position of Practice Manager.
							</p>
							<p>
								Mike found that there was little or no training provided by his
								existing employer. He felt that his knowledge and understanding
								of what leadership entails was lacking. He had recently
								struggled with a difficult employee over an HR-related incident
								and felt that he could have handled the matter better and
								resolved it in a more appropriate way.
							</p>
							<p>
								With the recent increase in staff levels, Mike realised that he
								had a strong desire to be liked and was having difficulty
								accepting that the Practice Manager was not always going to be
								the most popular person in the office.
							</p>
							<p>
								Mike asked me to help him perform better in his current role and
								become happier at work.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								You can read Mike’s executive coaching testimonial below.
							</h3>
						</Col>
					</Row>
					<HorizontalTestimonial
						name="Mike Practice Manager, West Sussex"
						review="It has been fantastic to have Jason to coach and mentor me. He is
          external to my organisation with ‘no axe to grind’ and has extensive
          experience managing staff in his own businesses over the years. Jason
          has helped me hold up a mirror to myself in order to see me as my
          team does. This could potentially be quite an uncomfortable experience
          but I have always felt safe and at ease with Jason and he is friendly,
          supportive and challenging at just the right times. The benefits of
          our work together have been rapidly visible to myself and my boss The
          insights, tools and tips I got were “spot on” every single time. I
          especially found the work we did around personality, motivation and
          communication powerful and easily applied to ‘real life’ I am greatly
          thankful for those learnings. Enlightening, challenging, inspiring,
          enjoyable and informative. Jason has a great sense of humour. Even
          though our 6 months programme has come to an end I feel I have a
          ‘little Jason’ on my shoulder reminding me of our learning together
          when I need a nudge. This experience will stay with me forever and I
          recommend you give it a go too"
						showLinkedIn="d-none"
						profilePic={mike}
					/>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Ready for executive coaching?
							</h2>
							<p>
								Every leader or manager who takes up my services is different,
								however, the case study above gives you a taste of how I’ve
								helped just one of a number of different professionals in a wide
								variety of industries from Sussex to London. From accountancy to
								insurance, education to the ‘not for profit’ or charitable
								sector to small business. I’d love to discuss how I could help
								you.
							</p>
							<p>
								I live in East Sussex, near Eastbourne (the parish of Pevensey
								and Westham actually), which means we can either meet locally
								for a face-to-face session or remote/virtual coaching via Zoom
								or Skype works just as well. Some clients opt for a mixture of
								both.
							</p>
							<p>
								So, while you’re here, why not book an initial discovery meeting
								with me?
							</p>
							<p>
								I’m looking forward to meeting you, hearing about your current
								circumstances and exploring how I can assist you.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">
								The success of your career is down to you
							</h3>
							<p>
								P.S. Executive coaching isn't a magic pill to business or career
								success - it's ultimately your responsibility to take the steps
								necessary to make it happen, but, throughout our coaching
								sessions with me as your executive coach, we'll think through
								whatever challenges you're currently experiencing, to set clear
								goals and develop a plan of action that fills you with the
								personal self confidence and support you need to achieve them.
							</p>
							<p>
								Don't delay, contact me today to schedule your phone or Zoom
								call to explain how executive coaching works and how it can be
								an invaluable tool for you and your career over the next 12
								months.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching in Sussex"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default ExecutiveCoachingSussexPage;
